<template>
  <v-container style="max-width: 99.5%" class="ml-0">
    <CrudTable
      :keyPaginacao="keyPaginacao"
      :headers="headers"
      :pagination="pagination"
      :items="items"
      :loading="loading"
      @actualPage="page"
    >
      <template v-slot:top>
        <div>
          <v-toolbar
            flat
            height="initial"
            :class="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'scroll ' : ''
            "
          >
            <div class="row m-0 pr-0 filtros-container">
              <div class="col-md-11 col-12">
                <div  class="filtros-area row col-12 mt-2">
                  <div class="col-md-4 col-12 pl-0 pr-0">
                    <div class="filtros-secao">
                      <span class="label-filtros-secao text-subtitle-1 font-weight-bold ml-1 ml-md-4">
                        Intervalo
                      </span>

                      <div class="filtros d-flex flex-column flex-md-row">
                        <DateField
                          class="pt-0 px-md-3 px-1"
                          cols="6"
                          label="Data Inicial"
                          :dateFormatted="dataInicial"
                          :date="dataInicial"
                          :limparInput="limparInputData"
                          @dateSelected="dataInicial = $event"
                        />
                        <DateField
                          class="pt-0 px-md-3 px-1"
                          cols="6"
                          label="Data Final"
                          :dateFormatted="dataFinal"
                          :date="dataFinal"
                          :initialDate="dataInicial"
                          :limparInput="limparInputData"
                          @dateSelected="dataFinal = $event"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-12 px-md-3 px-1">
                    <div class="filtros-secao filtro-tipo-beneficiado">
                      <span class="label-filtros-secao text-subtitle-1 font-weight-bold ml-1">
                        Tipo do Beneficiado
                      </span>

                      <div class="filtros">
                        <v-autocomplete
                          v-model="tipoBeneficiadoSelecionado"
                          outlined
                          label="Selecione o tipo"
                          item-value="id"
                          item-text="descricao"
                          no-data-text="Tipo de beneficiado não encontado"
                          :items="tiposBeneficiados"
                        >
                        </v-autocomplete>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-12 px-md-3 px-1">
                    <div class="filtros-secao filtro-nome-beneficiado">
                      <span class="label-filtros-secao text-subtitle-1 font-weight-bold ml-1">
                        Nome do Beneficiado
                      </span>

                      <div class="filtros">
                        <v-text-field
                          hide-details="auto"
                          outlined
                          label="Nome do beneficiado"
                          v-model="nomeBeneficiado"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="filtros-area second-row row col-12">
                  <div class="col-md-4 col-12 px-md-3 px-1">
                    <div class="filtros-secao filtro-nome-beneficiado">
                      <span class="label-filtros-secao text-subtitle-1 font-weight-bold ml-1">
                        Nome do Filial
                      </span>

                      <div class="filtros">
                        <v-text-field
                          hide-details="auto"
                          outlined
                          label="Nome do filial"
                          v-model="nomeParceiro"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-12 px-md-3 px-1">
                    <div class="filtros-secao filtro-nome-beneficiado">
                      <span class="label-filtros-secao text-subtitle-1 font-weight-bold ml-1">
                        Nome do Colabrador
                      </span>

                      <div class="filtros">
                        <v-text-field
                          hide-details="auto"
                          outlined
                          label="Nome do colaborador"
                          v-model="nomeColaborador"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-12 px-md-3 px-1">
                    <div class="filtros-secao filtro-tipo-beneficiado">
                      <span class="label-filtros-secao text-subtitle-1 font-weight-bold ml-1">
                        Status Agenda
                      </span>

                      <div class="filtros">
                        <v-autocomplete
                          v-model="filtroStatus"
                          outlined
                          label="Selecione o tipo"
                          item-value="id"
                          item-text="descricao"
                          no-data-text="Tipo de beneficiado não encontado"
                          :items="listaStatusAgenda"
                        >
                        </v-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="button-area col-md-1 col-12">
                <ButtonMain
                  class="btn-pesquisar"
                  :primary="true"
                  :loading="loading"
                  text="Pesquisar"
                  @click.native="pesquisarAgendamentos({ pesquisaInicical: true }); resetarPaginacao()"
                />

                <ButtonMain
                  v-if="verficarSePeloMenosUmFiltroTemValor()"
                  class="btn-limpar mt-2"
                  :primary="true"
                  :loading="loading"
                  text="Limpar Filtros"
                  @click.native="limparFiltros"
                />
              </div>

            </div>
          </v-toolbar>
        </div>
      </template>

      <template v-slot:[`item.statusPagamento`]="{ item }">
        <v-chip>
          {{ item.statusPagamento }}
        </v-chip>
      </template>

      <template v-slot:[`item.consultaOnline`]="{ item }">
        <span style="text-transform: uppercase">
          {{ item.consultaOnline === true ? 'Online' : 'Presencial' }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip @click="abrirDialogAlterarStatus(item)">
          {{ item.descricaoStatus }}
        </v-chip>
      </template>
    </CrudTable>

    <div class="text-center">
      <DialogConfirmation
        :key="dialogStatus"
        :tag="'activate'"
        :width="650"
        :title="'Atualização de status'"
        :show="dialogStatus"
        @close="fecharDialogAlterarStatus()"
        @confirm="confirmarAlteracaoStatus()"
      >
        <template v-slot:body>
          <v-card-text class="mt-5 mb-0">
            <span class="label-list-title">Atenção!</span>
            <ul class="aviso-cancelamento-agendamento">
              <li>Só haverá estorno de pagamento para cancelamentos executados com  mais de 24h de antecedência para a realização do agendamento.</li>
              <li>Cancelamento com menos de 24h não é permitido.</li>
            </ul>

            <span class="subtitle-1"> Selecione o novo status: </span>
            <v-chip-group v-if="dialogStatus" active-class="white--text green">
              <v-chip
                v-for="status in listaStatus"
                :key="status.id"
                @click="novoStatus = status.id"
              >
                {{ status.descricao }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </template>
      </DialogConfirmation>

      <DialogConfirmation
        :key="keyDialogEstorno"
        :tag="'edit'"
        :width="650"
        :title="'Aviso de Estorno'"
        :show="dialogEstorno"
        @close="dialogEstorno = false; keyDialogEstorno++"
        @confirm="confirmarCancelamentoAgendamentoComEstorno()"
      >
        <template v-slot:body>
          <div class="aviso-estorno">
            Este agendamento já foi pago. O cancelamento provocará o estorno do pagamento. Confirma a operação?
            </div>
        </template>
      </DialogConfirmation>
    </div>
  </v-container>
</template>

<script>
import CrudTable from '@/components/molecules/tables/CrudTable.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import DateField from '../../components/atoms/fields/DateField.vue'
import globalMethods from '../../mixins/globalMethods.vue'
import api from '@/services/api'

export default {
  components: { CrudTable, ButtonMain, DialogConfirmation, DateField },
  mixins: [globalMethods],
  data() {
    return {
      keyPaginacao: 0,
      keyDialogStatus: 0,
      keyDialogEstorno: 0,
      dialogStatus: false,
      dialogEstorno: false,
      novoStatus: '',
      loading: false,
      limparInputData: false,
      dataInicial: null,
      dataFinal: null,
      filtroStatus: null,
      nomeBeneficiado: null,
      nomeParceiro: null,
      nomeColaborador: null,
      tipoBeneficiadoSelecionado: null,
      dataEHoraAgendamento: null,
      statusPagamentoAgendamentoSelecionado: null,
      dialog: false,
      mensagemErroPadrao: 'Falha na conexão. Por favor, tente mais tarde.',
      headers: [
        { text: 'Beneficiado', align: 'start', value: 'beneficiado' },
        { text: 'Dia', align: 'start', value: 'data' },
        { text: 'Inicio', align: 'start', value: 'horaInicio' },
        { text: 'Fim', align: 'start', value: 'horaFim' },
        { text: 'Nome Beneficiado', align: 'start', value: 'nomeAssociado' },
        { text: 'Telefone Associado', align: 'center', value: 'telefone' },
        { text: 'Nome Filial', align: 'start', value: 'nomeParceiro' },
        { text: 'Nome Colaborador', align: 'start', value: 'nomeColaborador' },
        {
          text: 'Especialização',
          align: 'start',
          value: 'descricaoEspecializacao'
        },
        { text: 'Serviço', align: 'start', value: 'descricaoServico' },
        { text: 'Email Associado', align: 'start', value: 'email' },
        { text: 'Atendimento', align: 'start', value: 'consultaOnline' },
        { text: 'Status Pagamento', align: 'center', value: 'statusPagamento' },
        { text: 'Status Agenda', align: 'center', value: 'status' }
      ],
      tiposBeneficiados: [
        { id: 1, descricao: 'ASSOCIADO'},
        { id: 2, descricao: 'DEPENDENTE'},
      ],
      listaStatusAgenda: [
        { id: 2, descricao: 'AGENDADO'},
        { id: 3, descricao: 'REALIZADO'},
        { id: 7, descricao: 'NÃO REALIZADO'},
      ],
      items: [],
      listaStatus: {},
      horarioEscolhido: {},
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      },
    }
  },

  mixins: [globalMethods],

  methods: {
    verificarSeNaoPodeCancelarAgendamento(statusAgendamento, horasAteAgendamento){
      return statusAgendamento === 4 && this.statusPagamentoAgendamentoSelecionado === "CONFIRMADO" && horasAteAgendamento < 24;
    },

    confirmarAlteracaoStatus() {
      if (this.novoStatus != '') {
        const horasAteAgendamento = this.diferencaTempoAgoraAteDataEspecificada(this.dataEHoraAgendamento, 'hours');
        if(this.verificarSeNaoPodeCancelarAgendamento(this.novoStatus, horasAteAgendamento)) {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'ATENÇÃO',
            text: 'Agendamentos com pagamento já relalizado e com menos de 24h antes do horário agendado não podem ser cancelados.',
            time: 20000
          });
          this.statusPagamentoAgendamentoSelecionado = null;
          return;
        }

        if(this.novoStatus === 4 && this.statusPagamentoAgendamentoSelecionado === "CONFIRMADO" && horasAteAgendamento >= 24) {
          this.abrirDialogEstorno();
          return;
        }

        const dataEHoraAtual = this.horaAtual('America/Sao_Paulo', 'dd/MM/yyyy HH:mm');
        this.atualizarStatus(this.horarioEscolhido.id, this.novoStatus, { dataCancelamento: dataEHoraAtual });

      } else {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Nenhum status selecionado',
        })
      }
    },

    atualizarStatus(horarioEscolhidoId, novoStatus, paramnetros) {
      api.putStatusHorario(horarioEscolhidoId, novoStatus, paramnetros)
        .then(() => {
          this.pesquisarAgendamentos({ pesquisaInicical: false });
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Status alterado com sucesso.',
          });
          this.statusPagamentoAgendamentoSelecionado = null;
          this.dialogStatus = false;
          this.dialogEstorno = false;
          this.keyDialogStatus++;
          this.keyDialogEstorno++;
        })
        .catch((erro) => {
          const mensagemErro = erro?.response?.data;
          this.$root.vtoast.show({
            status: 'error',
            title: mensagemErro?.titulo || this.mensagemErroPadrao,
            text: mensagemErro?.mensagemUsuario || '',
            time: 25000
          });
        })
    },

    abrirDialogAlterarStatus(item) {
      api
        .getStatusHorario(item.idStatus)
        .then(({ data }) => {
          if(data.length) {
            this.dialogStatus = true;
            this.horarioEscolhido = item;
            this.listaStatus = data;
            this.statusPagamentoAgendamentoSelecionado = item.statusPagamento ? item.statusPagamento.toUpperCase() : null;
            this.dataEHoraAgendamento = `${item.data} ${item.horaInicio}`;
            return;
          }

          this.$root.vtoast.show({
            status: 'warning',
            title: 'Atenção!',
            text: `Nenhum status encontrado para ${item.descricaoStatus} `,
          });
          this.dialogStatus = false;
        })
    },

    fecharDialogAlterarStatus() {
      this.keyDialogStatus++;
      this.dialogStatus = false;
      this.novoStatus = '';
      this.listaStatus = '';
      this.horarioEscolhido = '';
    },

    abrirDialogEstorno() {
      this.dialogEstorno = true;
    },

    confirmarCancelamentoAgendamentoComEstorno(){
      const dataEHoraAtual = this.horaAtual('America/Sao_Paulo', 'dd/MM/yyyy HH:mm');
      this.atualizarStatus(this.horarioEscolhido.id, this.novoStatus, { dataCancelamento: dataEHoraAtual });
    },

    resetarPaginacao() {
      ++this.keyPaginacao;
    },

    pesquisarAgendamentos(opcoes = { pesquisaInicical }) {
      if(!this.verficarSePeloMenosUmFiltroTemValor()) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Preencha ou selecione pelo menos um filtro.',
        });

        return;
      }

      console.log({f: this.filtroStatus});

      this.loading = true;
      const filtro = {
        dataInicio: this.dataInicial,
        dataFim: this.dataFinal,
        beneficiario: this.tipoBeneficiadoSelecionado,
        nomeBeneficiario: this.nomeBeneficiado,
        status: this.filtroStatus,
        parceiro: this.nomeParceiro,
        colaborador: this.nomeColaborador,
        page: opcoes.pesquisaInicical ? 0 : this.pagination.page,
        size: this.pagination.perPage
      }

      api
        .getAgendaMonitoramento(filtro)
        .then(({ data }) => {
          this.items = data.content.map(item => {
            return {
              ...item,
              telefone: this.setTelefone(item.ddd, item.telefone),
            }
          });

          this.pagination.total = data.totalPages
          this.loading = false
          if (!data.content.length) {
            this.$root.vtoast.show({
              status: 'warning',
              title: 'Nenhum registro encontrado!',
            })
          }
        })
        .catch(erro => {
          const mensagemErro = erro?.response?.data;
          this.$root.vtoast.show({
            status: 'error',
            title: mensagemErro?.titulo || this.mensagemErroPadrao,
            text: mensagemErro?.mensagemUsuario || '',
            time: 25000
          });
        })
        .then(() => this.loading = false);
    },

    setTelefone(ddd, telefone) {
      if(ddd && telefone) return `(${ddd}) ${telefone}`;
      if(!ddd && telefone) return `${telefone}`;
      return null;
    },

    page(page) {
      this.loadingTable = true
      this.pagination.page = page - 1
      this.pesquisarAgendamentos({ pesquisaInicical: false })
    },

    verficarSePeloMenosUmFiltroTemValor() {
      return (
        (this.dataInicial && this.dataFinal)
        || this.nomeBeneficiado
        || this.filtroStatus
        || this.nomeParceiro
        || this.nomeColaborador
        || this.tipoBeneficiadoSelecionado
      ) ? true : false;
    },

    limparFiltros() {
      this.dataInicial = null;
      this.dataFinal = null;
      this.nomeBeneficiado = null;
      this.filtroStatus = null;
      this.nomeParceiro = null;
      this.nomeColaborador = null;
      this.tipoBeneficiadoSelecionado = null;
      this.limparInputData = true;
      this.items = [];
      setTimeout(() => this.limparInputData = false, 100);
    }
  }
}
</script>

<style scoped>
  .scroll {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .filtros-container {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 0.75rem;
  }

  .filtros-container .filtros-area {
    padding: 0;
  }

  .filtros-secao {
    display: flex;
    flex-direction: column;
  }

  .filtros-secao .label-filtros-secao {
    margin-bottom: 0.2rem;
  }

  .filtros-secao .filtros {
    display: flex;
  }

  .button-area {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .btn-pesquisar, .btn-limpar {
    height: 3.2rem !important;
    width: 10rem;
  }

  .btn-limpar {
    background: #fb8c00 !important;
  }

  .v-sheet.theme--light.v-toolbar.v-toolbar--flat {
    min-height: 15rem !important;
  }

  .label-list-title {
    color: #C62828;
    display: flex;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
  }

  .aviso-cancelamento-agendamento {
    color: #C62828 ;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-size: 1rem;
    padding-left: 0.6rem;
  }

  .aviso-cancelamento-agendamento > li {
    list-style-type: '* ';
  }

  .aviso-cancelamento-agendamento > li:not(:last-child) {
    margin-bottom: 0.4rem;
  }

  .aviso-estorno {
    font-size: 1.1rem;
    text-transform: uppercase;
    padding-top: 1rem;
  }

  .filtros-area.second-row {
    margin-top: -2.5rem
  }

  @media screen and (max-width: 959px) {
    .button-area {
      align-items: flex-start !important;
    }

    .filtros-area.second-row {
      margin-top: 0
    }
  }

  @media screen and (max-width: 560px) {
    .button-area  {
      margin-bottom: 1rem;
    }

    .button-area button {
      width: 100%;
    }

  }
</style>
