<template>
  <v-col cols="12" :md="cols">
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          v-model="dateText"
          hide-details="auto"
          persistent-hint
          v-bind="attrs"
          v-on="on"
          :label="label"
          :readonly="readonly"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        locale="pt"
        @input="menu1 = false"
      ></v-date-picker>
    </v-menu>
  </v-col>
</template>

<script>
import globalMethods from '@/mixins/globalMethods.vue'

export default {
  props: [
    'cols',
    'label',
    'dateFormatted',
    'initialDate',
    'limparInput',
    'readonly',
  ],
  data: (vm) => ({
    date: '',
    menu1: false,
    dateText: null
  }),

  watch: {
    limparInput(valor) {
      if(valor) {
        this.dateText = null
      };
    },

    dateText(value) {
      if(!value.length) {
        this.$emit('dateSelected', null)
      }
    },

    date(val) {
      this.dateText = this.date
        ? this.formatDate(this.date)
        : null;


      if (this.initialDate) {
        if (!this.greaterThan(this.dateText, this.initialDate)) {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'Atenção!',
            text: `Data Final (${this.dateText}) deve ser maior que a Data Inicial`,
          })
          this.date = new Date().toISOString().substr(0, 10)
          const today = this.dateFormatBR(
            new Date().toISOString().substr(0, 10)
          )
          this.$emit('dateSelected', today)
          this.dateText = today
        }
      }
      this.$emit('dateSelected', this.dateText)
      this.$emit('update:value', this.dateText)
    },
    dateFormatted: {
      immediate: true,
      handler(newText, oldText) {
        if ((newText === '' || newText) && newText !== oldText) {
          this.dateText = newText
        }
      }
    }
  },
  mixins: [globalMethods],
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  }
}
</script>
<style></style>
